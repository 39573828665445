.buyer-overview {
    .page-banner {
        background: url('../../../assets/contact-us-banner.webp');
        background-size: cover;
        background-repeat: no-repeat;
        padding: 120px 0px;
        position: relative;

        .overlay-text {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color: #000000, $alpha: 0.5);
            display: flex;
            align-items: center;
            justify-content: center;

            h1 {
                font-size: 50px;
                font-weight: 800;
                color: #FFF;
                margin-bottom: 0px;
                text-align: center;
            }

            h2 {
                font-size: 20px;
                font-weight: 500;
                font-style: italic;
                margin-top: 15px;
                margin-bottom: 0px;
                color: #FFF;
                text-align: center;
            }
        }
    }

    .page-content {
        padding: 60px 0px;

        .content {
            h2 {
                font-size: 30px;
                font-weight: 800;
                margin-bottom: 15px;
                color: #282E27;
            }
    
            p {
                font-size: 16px;
                font-weight: 500;
                line-height: 26px;
                margin-bottom: 20px;
            }

            ul {
                li {
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 10px;
                }
            }
    
            p.font-bold {
                font-weight: 700;
            }
    
            .list-boxes {
                .box {
                    width: 100%;
                    border-radius: 5px;
                    border: 1px solid #E5E5E5;
                    padding: 10px 15px;
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    
                    p {
                        font-size: 15px;
                        font-weight: 500;
                        margin-bottom: 0px;
                        color: #323232;
                        line-height: 22px;
                        text-align: center;
                    }
                }
            }
        }

        .content.margin {
            margin-top: 50px;
        }

        .content.with-image {
            margin-top: 50px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 5px;
                object-fit: cover;
            }
        }
    }
}